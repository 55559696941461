/* taken from https://vineethtrv.github.io/loader/ */
/* I like this one because it looks like a couple koi fish, playful */
.LoadingIndicator {
	/* Benigoi */
	--color-one: #e93a27;
	/* Kigoi */
	--color-two: #d7984b;

	--size: 48px;

	align-self: center;
	margin: auto;

	width: var(--size);
	height: var(--size);
	border-radius: 50%;
	display: inline-block;
	border-top: 4px solid var(--color-one);
	border-right: 4px solid transparent;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}

.LoadingIndicator::after {
	content: "";
	box-sizing: border-box;
	position: absolute;
	left: 0;
	top: 0;
	width: var(--size);
	height: var(--size);
	border-radius: 50%;

	border-left: 4px solid var(--color-two);
	border-bottom: 4px solid transparent;
	animation: rotation 0.5s linear infinite reverse;
	/* transform: rotate(180deg);
	border-top: 4px solid var(--color-two);
	border-right: 4px solid transparent; */
}

button .LoadingIndicator {
	--size: 1em;
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
