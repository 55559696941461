.HistoryEntry {
	flex: 0 0 auto;
	width: 100%;
	/*width: 200px;*/
	/*cursor: pointer;*/
	border: 0;
	border-bottom: 1px solid rgba(191, 191, 191, 0.63);
	padding: 5px;
	font: inherit;
	text-align: left;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	background: transparent;
	color: inherit;
	/* to allow space for a focus ring */
	margin-bottom: 2px;
}

[data-color-scheme="dark"] .HistoryEntry:active {
	background: rgba(99, 156, 255, 0.13);
	color: white;
}

[data-color-scheme="dark"] .HistoryEntry[aria-checked] {
	background: rgba(99, 156, 255, 0.527);
	color: white;
}

.HistoryEntry:active {
	background: rgb(240, 240, 240);
}

.HistoryEntry[aria-checked] {
	background: rgb(99, 155, 255);
}

.HistoryEntry:not(.current):not(.ancestor-of-current) {
	color: gray;
}

.HistoryEntry canvas,
.HistoryEntry svg,
.HistoryEntry .question-mark,
.HistoryEntry .ToolPreview {
	margin-right: 5px;
	vertical-align: middle;
}

[data-color-scheme="dark"] .HistoryEntry .ToolPreview {
	/* for now */
	background: lightgray;
}

.HistoryEntry .question-mark {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background: rgba(128, 128, 128, 0.5);
	color: white;
}

.HistoryEntry .question-mark::after {
	content: "?";
}
