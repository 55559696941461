.HistoryView {
	display: flex;
	flex-direction: column;
	overflow: auto;
	height: 100%;
	user-select: none;
	box-shadow: -1px 0 5px rgba(0, 0, 0, 0.5);
	background: white;
	color: black;
	/* it's not that good of a transition because the text color isn't transitioned, so make it relatively quick */
	transition: background 0.4s ease;
}

[data-color-scheme="dark"] .HistoryView {
	background: black;
	color: white;
}
