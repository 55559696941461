/*
for SCSS source (outdated now), see:
https://jsfiddle.net/1j01/s3gk4jun
https://codepen.io/1j01/pen/eYdreKy

Based on toggle buttons by @mallendeo https://codepen.io/mallendeo/pen/eLIiG

Gradient inspired by https://dribbble.com/shots/3348220-Night-Mode-Toggle#shot-description
*/

:root {
	--dark-mode-button-padding: 0.1em;
	--sun-yellow: #fd0;
	--night-light: #ffd;
	/* to edit this gradient: https://colorzilla.com/gradient-editor/#2929a7+0,7c57d8+40,17abfa+60,adeeff+100 */
	--sky: linear-gradient(to bottom, #2929a7 0%, #7c57d8 40%, #17abfa 60%, #adeeff 100%);
	--moon-shadow: var(--sky);
}

.dark-mode-button {
	box-sizing: border-box;
}

.dark-mode-button::-moz-selection {
	background: none;
}

.dark-mode-button::selection {
	background: none;
}

.dark-mode-button {
	border: 0;
	outline: 0;
	display: block;
	width: 4em;
	height: 2em;
	position: relative;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: transparent;
	border-radius: 2em;
	padding: var(--dark-mode-button-padding);
	background: var(--sky);
	background-size: 100% 230%;
	transition: background 0.4s ease;
	font-size: 20px;
}

.dark-mode-button:focus {
	box-shadow: 0 0 0px 2px #0084d2;
}

.dark-mode-button .sun-moon,
.dark-mode-button .sun-moon .shadow {
	position: relative;
	display: block;
	content: "";
	width: calc(2em - var(--dark-mode-button-padding) * 2);
	height: calc(2em - var(--dark-mode-button-padding) * 2);
}

.dark-mode-button .sun-moon {
	left: 0;
	overflow: hidden;
	border-radius: 50%;
	background: var(--sun-yellow);
	transition: left 0.2s ease, background 0.5s ease;
}

.dark-mode-button .sun-moon .shadow {
	position: absolute;
	border-radius: 50%;
	transition: background 0.4s ease, opacity 0.2s ease, left 0.2s ease;
	left: 100%;
	bottom: -0.5em;
	width: calc(3em - var(--dark-mode-button-padding) * 2);
	height: calc(3em - var(--dark-mode-button-padding) * 2);
	background: var(--moon-shadow);
	background-size: 100% 200%;
}

.dark-mode-button,
.dark-mode-button .sun-moon .shadow {
	background-position: 0 100%;
}

[data-color-scheme="dark"] .dark-mode-button .sun-moon {
	left: 50%;
}

[data-color-scheme="dark"] .dark-mode-button .sun-moon {
	background: var(--night-light);
	box-shadow: inset 0 0 1px 0px #231f6b;
}

[data-color-scheme="dark"] .dark-mode-button,
[data-color-scheme="dark"] .dark-mode-button .sun-moon .shadow {
	background-position: 0 0;
}

[data-color-scheme="dark"] .dark-mode-button .sun-moon .shadow {
	left: -90%;
	transition: background 0.4s ease, opacity 0.4s ease, left 1s ease;
}

[data-color-scheme="light"] .dark-mode-button .sun-moon .shadow {
	opacity: 0;
}

.dark-mode-button .rays {
	position: absolute;
	left: 0;
	top: 0;
	width: 2em;
	height: 2em;
	pointer-events: none;
}

.dark-mode-button .rays .ray {
	width: 0.5em;
	height: 0.3em;
	border-radius: 0.3em;
	background: var(--sun-yellow);
	position: absolute;
	left: 50%;
	top: 50%;
	transition: transform 0.2s ease, border-radius 0.2s ease, width 0.2s ease, height 0.2s ease, background 0.4s ease;
}

/* .dark-mode-button:focus .rays .ray {
	transition: transform 0.2s ease, border-radius  0.2s ease, width  0.2s ease, height  0.2s ease, background 0.4s ease, box-shadow 0.2s ease;
}
[data-color-scheme="light"] .dark-mode-button:focus .sun-moon,
[data-color-scheme="light"] .dark-mode-button:focus .rays .ray {
	box-shadow: 0 0 0 2px orange;
} */
.dark-mode-button .rays .ray:nth-child(1) {
	transform: translate(-50%, -50%) rotate(0.125turn) translate(1.3em, 0);
}

.dark-mode-button .rays .ray:nth-child(2) {
	transform: translate(-50%, -50%) rotate(0.25turn) translate(1.3em, 0);
}

.dark-mode-button .rays .ray:nth-child(3) {
	transform: translate(-50%, -50%) rotate(0.375turn) translate(1.3em, 0);
}

.dark-mode-button .rays .ray:nth-child(4) {
	transform: translate(-50%, -50%) rotate(0.5turn) translate(1.3em, 0);
}

.dark-mode-button .rays .ray:nth-child(5) {
	transform: translate(-50%, -50%) rotate(0.625turn) translate(1.3em, 0);
}

.dark-mode-button .rays .ray:nth-child(6) {
	transform: translate(-50%, -50%) rotate(0.75turn) translate(1.3em, 0);
}

.dark-mode-button .rays .ray:nth-child(7) {
	transform: translate(-50%, -50%) rotate(0.875turn) translate(1.3em, 0);
}

.dark-mode-button .rays .ray:nth-child(8) {
	transform: translate(-50%, -50%) rotate(1turn) translate(1.3em, 0);
}

.dark-mode-button .rays .ray:nth-child(9) {
	transform: translate(-50%, -50%) rotate(1.125turn) translate(1.3em, 0);
}

.dark-mode-button .rays .ray:nth-child(10) {
	transform: translate(-50%, -50%) rotate(1.25turn) translate(1.3em, 0);
}

.dark-mode-button .rays .ray:nth-child(11) {
	transform: translate(-50%, -50%) rotate(1.375turn) translate(1.3em, 0);
}

.dark-mode-button .rays .ray:nth-child(12) {
	transform: translate(-50%, -50%) rotate(1.5turn) translate(1.3em, 0);
}

.dark-mode-button .rays .ray:nth-child(13) {
	transform: translate(-50%, -50%) rotate(1.625turn) translate(1.3em, 0);
}

.dark-mode-button .rays .ray:nth-child(14) {
	transform: translate(-50%, -50%) rotate(1.75turn) translate(1.3em, 0);
}

.dark-mode-button .rays .ray:nth-child(15) {
	transform: translate(-50%, -50%) rotate(1.875turn) translate(1.3em, 0);
}

.dark-mode-button .rays .ray:nth-child(16) {
	transform: translate(-50%, -50%) rotate(2turn) translate(1.3em, 0);
}

[data-color-scheme="dark"] .dark-mode-button .rays .ray {
	width: 0.2em;
	height: 0.2em;
	border-radius: 0.2em;
	background: var(--night-light);
}

[data-color-scheme="dark"] .dark-mode-button .rays .ray:nth-child(1) {
	transform: translate(-50%, -50%) rotate(0turn) translate(0.236em, -0.0872em);
}

[data-color-scheme="dark"] .dark-mode-button .rays .ray:nth-child(2) {
	transform: translate(-50%, -50%) rotate(0turn) translate(-0.056em, 0.2912em);
}

[data-color-scheme="dark"] .dark-mode-button .rays .ray:nth-child(3) {
	transform: translate(-50%, -50%) rotate(0turn) translate(0.124em, 0.3352em);
}

[data-color-scheme="dark"] .dark-mode-button .rays .ray:nth-child(4) {
	transform: translate(-50%, -50%) rotate(0turn) translate(0.776em, 0.0448em);
}

[data-color-scheme="dark"] .dark-mode-button .rays .ray:nth-child(5) {
	transform: translate(-50%, -50%) rotate(0turn) translate(-0.1em, -0.58em);
}

[data-color-scheme="dark"] .dark-mode-button .rays .ray:nth-child(6) {
	transform: translate(-50%, -50%) rotate(0turn) translate(-0.504em, 0.2208em);
}

[data-color-scheme="dark"] .dark-mode-button .rays .ray:nth-child(7) {
	transform: translate(-50%, -50%) rotate(0turn) translate(-0.436em, 0.6872em);
}

[data-color-scheme="dark"] .dark-mode-button .rays .ray:nth-child(8) {
	transform: translate(-50%, -50%) rotate(0turn) translate(0.104em, 0.8192em);
}

[data-color-scheme="dark"] .dark-mode-button .rays .ray:nth-child(9) {
	transform: translate(-50%, -50%) rotate(0turn) translate(-0.884em, 0.6168em);
}

[data-color-scheme="dark"] .dark-mode-button .rays .ray:nth-child(10) {
	transform: translate(-50%, -50%) rotate(0turn) translate(0.6em, 0.08em);
}

[data-color-scheme="dark"] .dark-mode-button .rays .ray:nth-child(11) {
	transform: translate(-50%, -50%) rotate(0turn) translate(0.556em, -0.7912em);
}

[data-color-scheme="dark"] .dark-mode-button .rays .ray:nth-child(12) {
	transform: translate(-50%, -50%) rotate(0turn) translate(0.984em, -0.2368em);
}

[data-color-scheme="dark"] .dark-mode-button .rays .ray:nth-child(13) {
	transform: translate(-50%, -50%) rotate(0turn) translate(-0.116em, -0.0168em);
}

[data-color-scheme="dark"] .dark-mode-button .rays .ray:nth-child(14) {
	transform: translate(-50%, -50%) rotate(0turn) translate(-0.744em, -0.1312em);
}

[data-color-scheme="dark"] .dark-mode-button .rays .ray:nth-child(15) {
	transform: translate(-50%, -50%) rotate(0turn) translate(-0.9em, -0.58em);
}

[data-color-scheme="dark"] .dark-mode-button .rays .ray:nth-child(16) {
	transform: translate(-50%, -50%) rotate(0turn) translate(-0.584em, 0.3968em);
}
