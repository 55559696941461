.Colorbox {
	display: flex;
	flex-direction: row;
	/*justify-content: center;*/
	align-items: center;
	margin: 1rem;
}

.Colorbox-divider {
	border-left: 1px solid rgba(0, 0, 0, 0.5);
	margin: 0 8px;
	height: 42px;
}

.Colorbox-selected-swatch {
	margin: 2px;
	width: 42px;
	height: 42px;
}

/* TODO: maybe move to a swatch.css? included by both components that use the class? assuming it won't duplicate the css? */
/* oh, or use imports (assuming it won't duplicate the css) */
.swatch {
	min-width: 0 !important;
	border: none;
	border-radius: 2px;
	box-shadow: 0 0 0 1px black;
	border: 1px solid white;
	transition: border-color 1s ease;
}

[data-color-scheme="dark"] .swatch {
	border-color: rgba(255, 255, 255, 0.5);
}
