.Dialog {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	pointer-events: none;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.Dialog-box {
	pointer-events: auto;
	padding: 30px;
	background: white;
	color: black;
	border-radius: 5px;
	box-shadow: 0 1px 6px rgba(0, 0, 0, 0.4);
}

[data-color-scheme="dark"] .Dialog-box {
	background: black;
	color: white;
}

.Dialog-buttons {
	/* TODO: friendly buttons */
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	margin-top: 1em;
}

.Dialog-buttons button {
	padding: 0.4em 1.5em;
	background: rgba(128, 128, 128, 0.1);
	border: 1px solid rgba(191, 191, 191, 0.63);
	margin-left: 5px;
	border-radius: 2px;
	color: inherit;
}

.Dialog-buttons button:hover,
.Dialog-buttons button:focus {
	background: rgba(128, 128, 128, 0.2);
}

.Dialog-buttons button:active {
	background: rgba(128, 128, 128, 0.3);
}

.Dialog details {
	margin: 1em 0;
	width: 40em;
	max-width: calc(100vw - 2em);
	border: 1px solid rgba(128, 128, 128, 0.5);
}

.Dialog details>summary {
	background: rgba(128, 128, 128, 0.1);
	/*cursor: pointer;*/
	/* We could do a pointer hand, but then it feels like *everything* should have a pointer hand,
	and maybe it should, idk - it can be a useful cue to know when you can click on something -
	but let's just make it *not* show a text selection cursor (over the text) for now */
	cursor: default;
}

.Dialog details[open]>summary {
	border-bottom: 1px solid rgba(128, 128, 128, 0.5);
}

.Dialog details>.actual-details {
	max-height: 10em;
	overflow: auto;
}

.Dialog details>summary,
.Dialog details>.actual-details {
	padding: 0.5em;
}

.Dialog details pre {
	margin: 0;
}
